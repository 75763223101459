import { graphql } from "gatsby"
import React from "react"
import { copyright } from "./copyright.module.css"
import { Helmet } from "react-helmet"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Footer from "../components/footer/footer"

/* Components */
import StickyNav from "../components/sticky-nav/sticky-nav"

const Cookies = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes;
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.mkbservicedesk.nl/cookies/" />
      </Helmet>
      <header className={"header"}>
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main">
        <div className="container">
          <Seo title="Cookie Declaration" />
          <div className={copyright}>
            <h1>Cookie Declaration</h1>
            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/f30c60a3-f23c-4069-807d-8fa53a366ee2/cd.js"
              type="text/javascript"
              async
            ></script>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default Cookies

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
